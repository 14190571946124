var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "limiter scrollbar" },
            [
              _c(
                "TheTitle",
                { attrs: { icon: "library_music", title: "Audio" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      attrs: { slot: "left" },
                      slot: "left",
                    },
                    [
                      _vm.$permissions.isViewPodcastAllowed() &&
                      _vm.showPodcasts
                        ? _c("div", { staticClass: "flex items-end mr4" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "fw3 f5 dib link glow pt3 pb1 pointer",
                                class:
                                  _vm.selectedCollectionType === "ALL_PODCASTS"
                                    ? "light-gray bb b--white bw1"
                                    : "silver bb b--adori-transparent bw1",
                                on: {
                                  click: function ($event) {
                                    _vm.selectedCollectionType = "ALL_PODCASTS"
                                  },
                                },
                              },
                              [_vm._v("\n              Podcasts\n            ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f7 bg-adori-very-light-gray br3 white ml1",
                                staticStyle: {
                                  padding: "2px 5px",
                                  "margin-bottom": "6px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.rssFeedsCount) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.$permissions.isViewEpisodeAllowed()
                        ? _c("div", { staticClass: "flex items-end mr4" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "fw3 f5 dib link glow pt3 pb1 pointer",
                                class:
                                  _vm.selectedCollectionType === "ALL_AUDIOS"
                                    ? "light-gray bb b--white bw1"
                                    : "silver bb b--adori-transparent bw1",
                                on: {
                                  click: function ($event) {
                                    _vm.selectedCollectionType = "ALL_AUDIOS"
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              All audio tracks\n            "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f7 bg-adori-very-light-gray br3 white ml1",
                                staticStyle: {
                                  padding: "2px 5px",
                                  "margin-bottom": "6px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.allAudioTracksCount) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { attrs: { slot: "right" }, slot: "right" }, [
                    _c("div", { staticClass: "w-100 pt1" }, [
                      _vm.selectedCollectionType === "ALL_PODCASTS" &&
                      _vm.$permissions.isCreatePodcastAllowed()
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-between items-center light-gray",
                            },
                            [
                              _vm.rssFeedsCount !== 0
                                ? _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("BaseButtonBorder", {
                                        staticClass: "mr2",
                                        attrs: {
                                          text: "Transfer existing podcast",
                                          onClick: _vm.handleImportPodcast,
                                        },
                                      }),
                                      _c("BaseButtonBorder", {
                                        attrs: {
                                          text: "Create new podcast",
                                          onClick: _vm.handleCreatePodcast,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedCollectionType === "ALL_AUDIOS"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-between items-center light-gray",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c("div", { staticClass: "flex relative" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.audioSearchValue,
                                          expression: "audioSearchValue",
                                        },
                                      ],
                                      ref: "_searchAudios",
                                      staticClass:
                                        "bg-adori-gray bw0 br2 pl3 pr5 pv2 f6 white",
                                      staticStyle: {
                                        "margin-right": "10px",
                                        border: "1px solid #3f3f3f",
                                        width: "67%",
                                      },
                                      attrs: {
                                        autofocus: "",
                                        placeholder: "Search audio tracks",
                                      },
                                      domProps: { value: _vm.audioSearchValue },
                                      on: {
                                        keyup: function ($event) {
                                          return _vm.debouncedSearch()
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.audioSearchValue =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                    _vm.audioSearchValue.length === 0
                                      ? _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons silver absolute",
                                            staticStyle: {
                                              top: "5px",
                                              right: "20px",
                                            },
                                          },
                                          [_vm._v("search")]
                                        )
                                      : _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons silver absolute pointer dim",
                                            staticStyle: {
                                              top: "5px",
                                              right: "20px",
                                            },
                                            on: { click: _vm.clearSearch },
                                          },
                                          [_vm._v("close")]
                                        ),
                                  ]),
                                  _vm.$permissions.isCreateEpisodeShowAllowed(
                                    ""
                                  )
                                    ? _c("BaseButtonRed", {
                                        attrs: {
                                          text: "Upload a new audio track",
                                          onClick: _vm.handleUploadAudio,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
              _vm.selectedCollectionType === "ALL_PODCASTS"
                ? _c("div", [
                    _vm.rssFeedLoading
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-column justify-center items-center",
                          },
                          [
                            _c("div", {
                              staticClass:
                                "bg-adori-medium-gray br3 w-100 skeleton top-size",
                            }),
                            _c("div", {
                              staticClass:
                                "bg-adori-medium-gray mt1 br3 w-100 skeleton bottom-size",
                            }),
                          ]
                        )
                      : _c(
                          "div",
                          [
                            _vm.rssFeedsCount === 0
                              ? _c(
                                  "div",
                                  [
                                    _c("OverviewBanner", {
                                      attrs: {
                                        isLargeBanner: "",
                                        bannerHeader: _vm.bannerHeaderPodcasts,
                                        bannerSubHeader:
                                          _vm.bannerSubHeaderPodcasts,
                                        bannerImageUrl:
                                          _vm.bannerImageUrlPodcasts,
                                        bannerLocation: "ALL_PODCASTS",
                                      },
                                    }),
                                    _c("OverviewInfoCard", {
                                      attrs: { isColumn: "" },
                                    }),
                                  ],
                                  1
                                )
                              : _c("AllPodcastsPage", {
                                  ref: "allPodcastPage",
                                  attrs: {
                                    showEditButtons: "",
                                    showPreviewButton: "",
                                    editable: "",
                                    selectedPodcast: _vm.selectedPodcast,
                                    rssFeedsData: _vm.rssFeedsData.data,
                                  },
                                  on: { selectRss: _vm.selectRss },
                                }),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
              _vm.selectedCollectionType === "ALL_AUDIOS"
                ? _c("div", [
                    _vm.allAudioTracksLoading || _vm.rssFeedLoading
                      ? _c(
                          "div",
                          _vm._l(new Array(10), function (_, index) {
                            return _c("div", {
                              key: index,
                              staticClass:
                                "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size",
                            })
                          }),
                          0
                        )
                      : _c(
                          "div",
                          [
                            _vm.allAudioTracksCount === 0 &&
                            _vm.audioSearchValue.length === 0
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-column justify-center items-center w-100",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/no-episodes.svg"),
                                          alt: "",
                                          width: "500",
                                          height: "500",
                                        },
                                      }),
                                      _c("div", { staticClass: "white f3" }, [
                                        _vm._v("No Uploads Yet!"),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _c("AllAudioPage", {
                                  attrs: {
                                    editable: "",
                                    showEditButtons: "",
                                    searchValue: _vm.audioSearchValue,
                                    selectedPodcast: _vm.selectedPodcast,
                                  },
                                }),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }