




















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import debounce from 'lodash.debounce'
import AllAudioPage from '@/components/Audio/AllAudioPage.vue'
import AllPodcastsPage from '@/components/Podcast/AllPodcastsPage.vue'
import OverviewBanner from '@/components/Overview/OverviewBanner.vue'
import AllAudioInfoCard from '@/components/Audio/EmptyState/AllAudioInfoCard.vue'
import PublishInfoCard from '@/components/Publish/EmptyState/PublishInfoCard.vue'
import OverviewInfoCard from '@/components/Overview/EmptyState/OverviewInfoCard.vue'
import banner_podcast_image_svg from '@/assets/podcast_image_svg.svg'
import banner_no_episodes from '@/assets/no-episodes.svg'
import { reactive, SetupContext } from '@vue/composition-api'
import { useFetchRssFeed } from '@/hooks/rssFeeds'
import { useGetAllTracks } from '@/hooks/audio'
import get from 'lodash.get'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    AllAudioPage,
    AllPodcastsPage,
    OverviewBanner,
    AllAudioInfoCard,
    PublishInfoCard,
    OverviewInfoCard,
  },
  setup(_, context: SetupContext) {
    const allTrackParams: any = reactive({
      paginate: true,
      limit: 10,
      offset: 0,
      query: '',
      category: 'TRACK',
      fromYoutube: false,
    })
    const { data: rssFeedsData, isFetching: rssFeedLoading } = useFetchRssFeed(
      {
        limit: 200,
      },
      false,
      (data: any) => {
        context.root.$store.commit('setRssFeeds', data.data)
        context.root.$store.commit('setRssFeedsCount', data.count)
        context.root.$store.commit('setRssFeedsOffset', data.count)
        data?.data.map((rssFeed: any) =>
          context.root.$store.commit('addRssFeed', {
            rssFeed,
            rssFeedItems: [],
          })
        )
        context.root.$store.commit('setRssFeedUids')
      }
    )

    const { data: allAudioTracksData, isLoading: allAudioTracksLoading } = useGetAllTracks(allTrackParams)

    return {
      allTrackParams,
      rssFeedsData,
      rssFeedLoading,
      allAudioTracksData,
      allAudioTracksLoading,
    }
  },
})
export default class ViewAudio extends Vue {
  @Getter email!: string
  @Getter selectedAudioCollectionId!: any

  @Action setSelectedRssFeedUid!: any
  @Action showImportPodcast!: any
  @Action showCreatePodcast!: any
  @Action setAllAudioSearchValue!: any

  rssFeedsData!: any
  rssFeedLoading!: boolean
  allAudioTracksData!: any
  allAudioTracksLoading!: boolean
  allTrackParams!: any

  selectedCollectionType: string = ''
  selectedRssFeed!: string
  audioSearchValue: string = ''

  // Empty state values for Audio -> Podcasts
  bannerHeaderPodcasts: string = 'Create a new podcast'
  bannerSubHeaderPodcasts: string =
    'Launch and deliver unlimited podcast shows and episodes. <i>Adorify</i> your episodes with interactive tags to augment your story, and measure the jump in listener engagement and monetization opportunities!'
  bannerImageUrlPodcasts: string = banner_podcast_image_svg

  // Empty state values for Audio -> All Audio
  bannerHeaderAudio: string = 'New audio experiences'
  bannerSubHeaderAudio: string =
    '<i>Adorify</i> your audio content with 1-click. Generate transcripts, and review auto-suggested interactive tags embedded into your audio hotspots. Distribute your tracks as interactive podcasts, embeddable web content, and more!'
  bannerImageUrlAudio: string = banner_no_episodes

  get showPodcasts() {
    if (this.email === 'demo+apple@adorilabs.com') {
      return false
    }
    return true
  }

  get rssFeedsCount() {
    return get(this.rssFeedsData, 'count', 0)
  }
  get allAudioTracksCount() {
    return this.allAudioTracksData?.count
  }

  get selectedCollectionId() {
    if (this.selectedCollectionType === 'ALL_AUDIOS') {
      return this.selectedAudioCollectionId
    }
    if (this.selectedCollectionType === 'ALL_PODCASTS') {
      return this.selectedRssFeed
    }
    return null
  }

  get selectedPodcast() {
    const uid = this.$route.params.id
    return uid
      ? this.rssFeedsData?.rssFeedByUid[this.selectedRssFeed] || this.rssFeedsData?.data[0]
      : this.rssFeedsData?.data[0]
  }

  @Watch('rssFeedsData')
  watchRssFeedData() {
    this.handleRoutes()
  }

  @Watch('selectedCollectionType')
  watchCollectionTypeChange() {
    const uid = this.$route.params.id
    !uid &&
      this.selectedCollectionType === 'ALL_PODCASTS' &&
      this.$router.push(`/audio/podcasts/${this.rssFeedsData.data[0]?.uid}`)
    this.selectedCollectionType === 'ALL_AUDIOS' && this.$router.push(`/audio/all`)
  }

  handleRoutes() {
    const channel = this.$route.params.channel
    const uid = this.$route.params.id
    if (channel === 'podcasts' && !uid) {
      this.selectedRssFeed = this.rssFeedsData.data[0]?.uid
      this.setSelectedRssFeedUid(this.selectedRssFeed)
      this.$router.push(`/audio/podcasts/${this.selectedRssFeed}`)
      this.selectedCollectionType = 'ALL_PODCASTS'
    } else if (channel === 'podcasts' && uid) {
      this.selectedRssFeed = uid
      this.setSelectedRssFeedUid(uid)
      this.selectedCollectionType = 'ALL_PODCASTS'
    } else if (channel === 'all') {
      this.selectedCollectionType = 'ALL_AUDIOS'
    }
    // @ts-ignore
    this.$refs?.allPodcastPage.selectRssFeedUid(this.selectedRssFeed)
  }

  created() {
    sessionStorage.removeItem('upload')
    sessionStorage.removeItem('feedUrl')
    sessionStorage.removeItem('feedUid')
    this.$store.commit('resetYoutubeSetting')
    this.$store.commit('resetPodcastSetting')
    this.$store.commit('setYTModalId', null)
    this.$store.commit('clearYoutubeState')
    this.handleRoutes()
    this.$watch(
      () => this.$route,
      () => {
        this.handleRoutes()
      }
    )
  }

  async clearSearch() {
    this.audioSearchValue = ''
    await this.onAudioSearchValueChanged()
  }

  async onAudioSearchValueChanged() {
    this.allTrackParams.query = this.audioSearchValue
  }

  handleImportPodcast() {
    this.showImportPodcast()
  }

  handleCreatePodcast() {
    this.showCreatePodcast()
  }

  debouncedSearch = debounce(() => {
    this.onAudioSearchValueChanged()
  }, 600)

  handleUploadAudio() {
    this.$store.commit('setYoutubeModal', 'AUDIO')
    this.$store.commit('setCurrentModal', 'importAndUpload')
  }

  selectRss(feedUid: string) {
    this.selectedRssFeed = feedUid
    this.setSelectedRssFeedUid(feedUid)
    this.$router.push(`/audio/podcasts/${feedUid}`)
  }
}
